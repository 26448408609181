/* Add these styles at the beginning of the file */
body {
  margin: 0;
  padding: 0;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
}

body.dark-mode {
  background-color: #121212;
  color: #e0e0e0;
}

/* Modern Base Styles */
.dashboard {
  padding: 1.5rem;
  max-width: 1400px;
  margin: 0 auto;
  min-height: 100vh;
  background: var(--bg-gradient);
}

/* Light mode variables */
:root {
  --bg-gradient: linear-gradient(135deg, #f5f7fa 0%, #e4e9f2 100%);
  --card-bg: #ffffff;
  --card-shadow: 0 8px 24px rgba(149, 157, 165, 0.1);
  --card-hover-shadow: 0 12px 28px rgba(149, 157, 165, 0.2);
  --primary-color: #3b82f6;
  --secondary-color: #6366f1;
  --accent-color: #8b5cf6;
  --accent-gradient: linear-gradient(135deg, #6366f1 0%, #8b5cf6 100%);
  --danger-color: #ef4444;
  --danger-gradient: linear-gradient(135deg, #ef4444 0%, #f87171 100%);
  --success-color: #10b981;
  --success-gradient: linear-gradient(135deg, #10b981 0%, #34d399 100%);
  --text-primary: #1f2937;
  --text-secondary: #4b5563;
  --border-color: #e5e7eb;
  --tag-bg: #f3f4f6;
  --tag-color: #374151;
  --button-hover: #2563eb;
}

/* Update the existing .dashboard class */
.dashboard {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.tabs {
  display: flex;
}

.tabs button {
  background-color: #f0f0f0;
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 5px 5px 0 0;
  transition: background-color 0.3s;
}

.tabs button.active {
  background-color: #2196f3;
  color: white;
}

.dashboard-controls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.refresh-button svg,
.theme-toggle svg {
  margin-right: 5px;
}

.refresh-button {
  background-color: #4caf50;
  color: white;
}

.theme-toggle {
  background: none;
  border: 2px solid #2196f3;
  color: #2196f3;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 5px 10px;
  margin-left: 10px;
  border-radius: 4px;
  transition:
    background-color 0.3s,
    color 0.3s;
}

.theme-toggle:hover {
  background-color: #2196f3;
  color: white;
}

.dark-mode .theme-toggle {
  border-color: #bb86fc;
  color: #bb86fc;
}

.dark-mode .theme-toggle:hover {
  background-color: #bb86fc;
  color: #121212;
}

.settings-icon {
  font-size: 20px;
}

.message-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.message-title {
  font-size: 18px;
  margin: 0;
  flex-grow: 1;
}

.message-actions {
  display: flex;
  gap: 8px;
  position: relative;
}

.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  padding: 4px;
  color: #777;
  transition: color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-button:hover {
  color: #333;
}

.dark-mode .icon-button:hover {
  color: #fff;
}

.message-content {
  font-size: 14px;
  color: #555;
  margin-bottom: 12px;
}

.message-preview-image {
  width: 100%;
  height: 200px; /* You can adjust this value as needed */
  object-fit: cover;
  object-position: center;
  margin-bottom: 12px;
}

.message-url {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.message-link {
  color: #2196f3;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
}

.copy-link-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-left: 8px;
  flex-shrink: 0;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 12px;
}

.tag {
  background-color: #e0e0e0;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.dark-mode .tag {
  background-color: #555;
  color: #fff;
}

.message-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  color: #777;
}

.delete-button,
.mark-done-button {
  padding: 4px 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.delete-button {
  background-color: #f44336;
  color: white;
}

.mark-done-button {
  background-color: #4caf50;
  color: white;
}

.dark-mode .tag {
  background-color: #555;
  color: #fff;
}

.dark-mode .message-link {
  color: #64b5f6;
}

.dark-mode .message-title {
  color: #ffffff;
}

.dark-mode .message-content {
  color: #b0b0b0;
}

.dark-mode .message-timestamp {
  color: #888;
}

.dark-mode .message-link {
  color: #bb86fc;
}

.dark-mode .message-link:hover {
  color: #bb86fc;
  text-decoration: underline;
}

.dark-mode .copy-link-button {
  color: #bb86fc;
}

.dark-mode .tag {
  background-color: #333;
  color: #e0e0e0;
}

.dark-mode .message-card.done {
  background-color: #1a1a1a;
  opacity: 0.7;
}

/* Add these styles for tag management and display */
.tag-management {
  margin-top: 20px;
}

.tag-management form {
  display: flex;
  margin-bottom: 10px;
}

.tag-management input {
  flex-grow: 1;
  margin-right: 10px;
}

.tag-management ul {
  list-style-type: none;
  padding: 0;
}

.tag-management li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.tags {
  margin-top: 5px;
}

.tag {
  background-color: #f0f0f0;
  border-radius: 3px;
  padding: 2px 5px;
  margin-right: 5px;
  font-size: 0.8em;
}

.warm-style {
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  transition:
    background-color 0.3s,
    color 0.3s;
}

.dashboard.dark-mode .warm-style {
  background-color: #333;
  color: #f9f5f0;
}

.dashboard:not(.dark-mode) .warm-style {
  background-color: #f9f5f0;
  color: #333;
}

.tag-input {
  width: 150px; /* Make the input box smaller */
  padding: 6px; /* Adjust padding for a more compact look */
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
  transition:
    background-color 0.3s,
    color 0.3s;
}

.dashboard.dark-mode .tag-input {
  background-color: #555;
  color: #f9f5f0;
  border: 1px solid #777;
}

.dashboard:not(.dark-mode) .tag-input {
  background-color: #fff;
  color: #333;
}

.add-tag-button {
  background-color: #ff7f50;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.add-tag-button:hover {
  background-color: #ff6347;
}

.tag-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

/* Remove or comment out these styles */
/*
.tag-chip {
  background-color: #f0f0f0;
  color: #333;
  padding: 5px 10px;
  border-radius: 16px;
  margin: 5px;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
}

.dashboard.dark-mode .tag-chip {
  background-color: #333;
  color: #e0e0e0;
  border: 1px solid #555;
}
*/

/* Add these styles to the existing Dashboard.css file */

.dashboard-controls {
  display: flex;
  align-items: center;
}

.settings-icon {
  vertical-align: middle;
}

.add-tag-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 0.8em;
  margin-left: 5px;
  padding: 0;
  color: var(--text-color);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 5px;
  max-width: 500px;
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;
}

.dark-mode .modal-content {
  background-color: #333;
  color: #e0e0e0;
}

.close-modal-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.2em;
  cursor: pointer;
  color: #333;
}

.dark-mode .close-modal-button {
  color: #e0e0e0;
}

.tag-selection-modal h2 {
  margin-top: 0;
}

.tag-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.tag-chip {
  background-color: #f0f0f0;
  border: none;
  padding: 5px 10px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dark-mode .tag-chip {
  background-color: #555;
}

.tag-chip.selected {
  background-color: #4caf50;
  color: white;
}

.dark-mode .tag-chip.selected {
  background-color: #66bb6a;
}

.add-tag-chip {
  background-color: #e0e0e0;
  border: none;
  padding: 5px 10px;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dark-mode .add-tag-chip {
  background-color: #444;
  color: #e0e0e0;
}

.add-tag-form {
  display: flex;
  margin-bottom: 20px;
}

.tag-input {
  flex-grow: 1;
  margin-right: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.dark-mode .tag-input {
  background-color: #444;
  color: #e0e0e0;
  border-color: #555;
}

.add-tag-button {
  padding: 5px 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.dark-mode .add-tag-button {
  background-color: #66bb6a;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
}

.save-button,
.cancel-button {
  padding: 5px 10px;
  margin-left: 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.save-button {
  background-color: #2196f3;
  color: white;
}

.cancel-button {
  background-color: #f44336;
  color: white;
}

.dark-mode .save-button {
  background-color: #42a5f5;
}

.dark-mode .cancel-button {
  background-color: #ef5350;
}

/* Add these styles at the end of the file */

.active-filters {
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 0;
}

.active-filters span {
  margin-right: 5px;
}

.filter-tag {
  display: inline-flex;
  align-items: center;
  background-color: #e0e0e0;
  color: #333;
  padding: 4px 8px;
  border-radius: 4px;
  margin-right: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 14px;
}

.dark-mode .filter-tag {
  background-color: #555;
  color: #e0e0e0;
}

.filter-tag:hover {
  background-color: #d0d0d0;
}

.dark-mode .filter-tag:hover {
  background-color: #666;
}

.filter-tag svg {
  margin-left: 5px;
}

.clear-filters {
  background-color: transparent;
  color: #f44336;
  border: none;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 14px;
}

.clear-filters:hover {
  background-color: rgba(244, 67, 54, 0.1);
}

.dark-mode .clear-filters {
  color: #ff7961;
}

.dark-mode .clear-filters:hover {
  background-color: rgba(255, 121, 97, 0.1);
}

.tag {
  cursor: pointer;
  transition: background-color 0.3s;
}

.tag:hover {
  background-color: #d0d0d0;
}

.dark-mode .tag:hover {
  background-color: #444;
}

/* Add these styles to the existing Dashboard.css file */

.tabs {
  display: flex;
  margin-bottom: 20px;
}

.tabs button {
  background-color: #f0f0f0;
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.tabs button.active {
  background-color: #2196f3;
  color: white;
}

.dark-mode .tabs button {
  background-color: #333;
  color: #e0e0e0;
}

.dark-mode .tabs button.active {
  background-color: #bb86fc;
  color: #121212;
}

.message-type-dropdown {
  position: relative;
  display: inline-block;
}

.type-select {
  appearance: none;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px 24px 4px 8px;
  font-size: 0.8rem;
  cursor: pointer;
  color: #666;
}

.type-select:hover {
  background-color: #e8e8e8;
}

.dropdown-icon {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #666;
  font-size: 0.7rem;
}
/* ... (keep existing styles) */

/* Add these styles */
.message-card.overdue {
  border-left: 3px solid #f44336; /* Red border for overdue messages */
}

.reminder-controls {
  display: flex;
  align-items: center;
  gap: 8px;
}

.reminder-date {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  color: #666;
  margin-left: auto;
}

.dark-mode .reminder-date {
  color: #aaa;
}

.snooze-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
  padding: 4px;
  border-radius: 4px;
}

.dark-mode .snooze-button {
  color: #aaa;
}

.snooze-options {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 120px;
}

.dark-mode .snooze-options {
  background-color: #333;
  border-color: #444;
}

.snooze-options button {
  background: none;
  border: none;
  padding: 4px 8px;
  cursor: pointer;
  text-align: left;
  border-radius: 2px;
}

.snooze-options button:hover {
  background-color: #f0f0f0;
}

.dark-mode .snooze-options button:hover {
  background-color: #444;
}

/* Add these styles */
.message-card.has-reminder {
  border-top: 3px solid #2196f3;
}

.dark-mode .message-card.has-reminder {
  border-top-color: #bb86fc;
}

.reminder-indicator {
  margin-left: 8px;
  color: #2196f3;
  font-size: 14px;
}

.dark-mode .reminder-indicator {
  color: #bb86fc;
}

.custom-date-picker {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 200px;
}

.dark-mode .custom-date-picker {
  background-color: #333;
  border-color: #444;
}

.custom-date-picker input {
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.dark-mode .custom-date-picker input {
  background-color: #444;
  border-color: #555;
  color: #e0e0e0;
}

.custom-date-actions {
  display: flex;
  gap: 8px;
  margin-top: 8px;
}

.custom-date-actions button {
  flex: 1;
  padding: 6px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.custom-date-actions button:first-child {
  background-color: #2196f3;
  color: white;
}

.custom-date-actions button:last-child {
  background-color: #f44336;
  color: white;
}

.dark-mode .custom-date-actions button:first-child {
  background-color: #42a5f5;
}

.dark-mode .custom-date-actions button:last-child {
  background-color: #ef5350;
}

.snooze-options button {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.snooze-options button svg {
  font-size: 14px;
}

/* Add these styles */
.message-card.snoozed {
  border-right: 3px solid #9c27b0; /* Purple border for snoozed messages */
}

.message-card.overdue {
  border-left: 3px solid #f44336; /* Red border for overdue messages */
}

.message-card.has-reminder {
  border-top: 3px solid #2196f3; /* Blue border for messages with reminders */
}

.snoozed-indicator {
  margin-left: 8px;
  color: #9c27b0;
  font-size: 14px;
}

.overdue-indicator {
  margin-left: 8px;
  color: #f44336;
  font-size: 14px;
  animation: pulse 2s infinite;
}

.dark-mode .message-card.snoozed {
  border-right-color: #ce93d8;
}

.dark-mode .message-card.overdue {
  border-left-color: #ef5350;
}

.dark-mode .message-card.has-reminder {
  border-top-color: #42a5f5;
}

.dark-mode .snoozed-indicator {
  color: #ce93d8;
}

.dark-mode .overdue-indicator {
  color: #ef5350;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

/* Update existing styles */
.reminder-date {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  color: #666;
  margin-left: auto;
}

.reminder-date.snoozed {
  color: #9c27b0;
}

.reminder-date.overdue {
  color: #f44336;
}

.dark-mode .reminder-date.snoozed {
  color: #ce93d8;
}

.dark-mode .reminder-date.overdue {
  color: #ef5350;
}

/* Update the message footer to show status */
.message-footer .reminder-date {
  display: flex;
  align-items: center;
  gap: 4px;
}

.message-footer .reminder-status {
  font-size: 12px;
  font-weight: 500;
  margin-left: 8px;
}

.message-footer .reminder-status.snoozed {
  color: #9c27b0;
}

.message-footer .reminder-status.overdue {
  color: #f44336;
}

.dark-mode .message-footer .reminder-status.snoozed {
  color: #ce93d8;
}

.dark-mode .message-footer .reminder-status.overdue {
  color: #ef5350;
}

/* Keep only the overdue border style */
.message-card.overdue {
  border-left: 3px solid #f44336; /* Red border for overdue messages */
}

.dark-mode .message-card.overdue {
  border-left-color: #ef5350;
}

/* Remove these styles
.message-card.snoozed {
  border-right: 3px solid #9c27b0;
}

.message-card.has-reminder {
  border-top: 3px solid #2196F3;
}
*/

/* Keep the indicator styles */
.reminder-indicator {
  margin-left: 8px;
  color: #2196f3;
  font-size: 14px;
}

.snoozed-indicator {
  margin-left: 8px;
  color: #9c27b0;
  font-size: 14px;
}

.overdue-indicator {
  margin-left: 8px;
  color: #f44336;
  font-size: 14px;
  animation: pulse 2s infinite;
}

/* Header Styles */
.dashboard h1 {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--text-primary);
  margin-bottom: 1.5rem;
  letter-spacing: -0.025em;
}

/* Dashboard Controls */
.dashboard-header {
  background: linear-gradient(135deg, var(--card-bg), var(--card-bg));
  border-radius: 1rem;
  padding: 1.5rem;
  margin-bottom: 2rem;
  box-shadow: var(--card-shadow);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  position: relative;
  border-bottom: 1px solid var(--border-color);
  overflow: hidden;
}

@media (min-width: 768px) {
  .dashboard-header {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

/* Tabs */
.tabs {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.tabs button {
  padding: 0.75rem 1.25rem;
  border-radius: 0.75rem;
  font-weight: 500;
  transition: all 0.2s ease;
  background: transparent;
  color: var(--text-secondary);
  border: 1px solid var(--border-color);
  position: relative;
  overflow: hidden;
}

.tabs button:hover {
  background: var(--tag-bg);
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.tabs button.active {
  background: var(--accent-gradient);
  color: white;
  border: none;
  box-shadow: 0 0 20px rgba(99, 102, 241, 0.3);
}

/* Overdue tab styling */
.tabs button[data-tab="overdue"] {
  background: linear-gradient(135deg, #ef4444, #f87171);
  color: white;
  border: none;
}

.tabs button[data-tab="overdue"]:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(239, 68, 68, 0.2);
}

.tabs button[data-tab="overdue"].active {
  box-shadow: 0 0 20px rgba(239, 68, 68, 0.3);
}

.overdue-count {
  background: rgba(255, 255, 255, 0.2);
  padding: 0.25rem 0.5rem;
  border-radius: 1rem;
  font-size: 0.875rem;
  margin-left: 0.5rem;
}

/* Add tag button styling */
.add-tag-button {
  background: var(--accent-gradient);
  color: white;
  border: none;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.add-tag-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(99, 102, 241, 0.2);
}

/* Message card overdue styling */
.message-card.overdue {
  border-left: 4px solid var(--danger-color);
  background: linear-gradient(to right, rgba(239, 68, 68, 0.05), transparent);
}

.message-card.overdue::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--danger-gradient);
  opacity: 0.05;
  pointer-events: none;
}

/* Success actions */
.mark-done-button {
  background: var(--success-gradient);
  color: white;
}

.mark-done-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(16, 185, 129, 0.2);
}

/* Update dashboard-controls to better organize the buttons */
.dashboard-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
}

/* Update active filters styling */
.active-filters {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding-right: 1rem;
}

.active-filters span:first-child {
  color: var(--text-secondary);
  font-weight: 500;
}

.filter-tag {
  background: linear-gradient(
    135deg,
    var(--tag-bg),
    rgba(var(--accent-color), 0.1)
  );
  border: 1px solid var(--border-color);
  padding: 0.25rem 0.75rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  display: inline-flex;
  align-items: center;
  gap: 0.375rem;
  transition: all 0.2s ease;
  cursor: pointer;
}

.filter-tag:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  border-color: var(--accent-color);
}

.clear-filters {
  color: var(--danger-color);
  background: transparent;
  border: none;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0.25rem 0.5rem;
  border-radius: 0.375rem;
  transition: all 0.2s ease;
}

.clear-filters:hover {
  background: rgba(239, 68, 68, 0.1);
}

/* Add gradient overlay */
.dashboard-header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    135deg,
    rgba(99, 102, 241, 0.05) 0%,
    rgba(139, 92, 246, 0.05) 100%
  );
  pointer-events: none;
}

/* Dark mode gradient */
.dark-mode .dashboard-header::before {
  background: linear-gradient(
    135deg,
    rgba(99, 102, 241, 0.1) 0%,
    rgba(139, 92, 246, 0.1) 100%
  );
}

/* Enhanced tab styling */
.tabs button {
  padding: 0.75rem 1.25rem;
  border-radius: 0.75rem;
  font-weight: 500;
  transition: all 0.2s ease;
  background: transparent;
  color: var(--text-secondary);
  border: 1px solid var(--border-color);
  position: relative;
  overflow: hidden;
}

.tabs button:hover {
  background: var(--tag-bg);
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

/* Active tab styling with glow effect */
.tabs button.active {
  background: var(--accent-gradient);
  color: white;
  border: none;
  box-shadow: 0 0 20px rgba(99, 102, 241, 0.3);
}

.dark-mode .tabs button.active {
  box-shadow: 0 0 20px rgba(139, 92, 246, 0.3);
}

/* Enhanced control buttons */
.control-buttons {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding-left: 1rem;
  position: relative;
}

/* Fancy border separator */
.control-buttons::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 70%;
  width: 2px;
  background: linear-gradient(
    to bottom,
    transparent,
    var(--border-color),
    transparent
  );
}

/* Enhanced filter tags */
.filter-tag {
  background: linear-gradient(
    135deg,
    var(--tag-bg),
    rgba(var(--accent-color), 0.1)
  );
  border: 1px solid var(--border-color);
  padding: 0.25rem 0.75rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  display: inline-flex;
  align-items: center;
  gap: 0.375rem;
  transition: all 0.2s ease;
  cursor: pointer;
}

.filter-tag:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  border-color: var(--accent-color);
}

/* Overdue tab special styling */
.tabs button[data-tab="overdue"] {
  background: linear-gradient(135deg, #ef4444, #f87171);
  color: white;
  border: none;
}

.tabs button[data-tab="overdue"]:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(239, 68, 68, 0.2);
}

.tabs button[data-tab="overdue"].active {
  box-shadow: 0 0 20px rgba(239, 68, 68, 0.3);
}

/* Enhanced dark mode support */
.dark-mode .dashboard-header {
  background: linear-gradient(
    135deg,
    rgba(30, 41, 59, 0.8),
    rgba(30, 41, 59, 0.9)
  );
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
}

.dark-mode .filter-tag {
  background: linear-gradient(135deg, var(--tag-bg), rgba(139, 92, 246, 0.1));
}

.dark-mode .control-buttons::before {
  background: linear-gradient(
    to bottom,
    transparent,
    rgba(255, 255, 255, 0.1),
    transparent
  );
}

.dropdown-trigger:hover {
  background: var(--tag-bg);
}

.chevron {
  transition: transform 0.2s ease;
}

.chevron.open {
  transform: rotate(180deg);
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.dropdown-menu button {
  width: 100%;
  padding: 0.75rem 1rem;
  text-align: left;
  border: none;
  background: transparent;
  color: var(--text-primary);
  cursor: pointer;
  transition: all 0.2s ease;
}

.dropdown-menu button:hover {
  background: var(--tag-bg);
}

.dropdown-menu button.active {
  background: var(--accent-gradient);
  color: white;
}

/* Dark mode support */
.dark-mode .search-bar input {
  background: rgba(255, 255, 255, 0.05);
}

.dark-mode .dropdown-menu {
  background: rgba(30, 41, 59, 0.95);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border-color: var(--border-color);
}

/* Add transition for filtered messages */
.message-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  transition: all 0.3s ease;
}

.message-card {
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Update dashboard header layout */
.dashboard-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  gap: 1rem;
  flex-wrap: nowrap;
  z-index: 1000;
}

/* Update dashboard controls layout */
.dashboard-controls {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: nowrap;
}

/* Make controls responsive */
@media (max-width: 768px) {
  .dashboard-header {
    flex-direction: column;
    align-items: stretch;
  }

  .header-controls {
    flex-direction: column;
    gap: 0.5rem;
  }

  .search-bar {
    max-width: 100%;
  }

  .category-dropdown {
    width: 100%;
    max-width: 100%;
  }

  .dashboard-controls {
    flex-wrap: wrap;
  }
}

/* Update control buttons layout */
.control-buttons {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-left: 1rem;
  position: relative;
  flex-wrap: nowrap;
}

/* Update dropdown menu styles to handle overflow */
.dropdown-menu {
  position: absolute;
  top: calc(100% + 0.5rem);
  left: 0;
  right: 0;
  background: var(--card-bg);
  border-radius: 0.75rem;
  border: 1px solid var(--border-color);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  animation: slideDown 0.2s ease;
  z-index: 1000; /* Ensure dropdown appears above other content */
}

/* Update category dropdown styles */
.category-dropdown {
  position: relative;
  z-index: 1000; /* Ensure dropdown appears above other content */
  min-width: 140px;
  max-width: 200px;
}

/* Update dashboard header to handle overflow */
.dashboard-header {
  position: relative; /* Ensure this is a positioning context */
  overflow: visible; /* Allow dropdowns to overflow */
}
