/* frontend/src/components/ConfirmationModal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.modal-content.dark-mode {
  background-color: #333;
  color: #fff;
}

.modal-content button {
  margin: 0 10px;
  padding: 5px 15px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.modal-content button:first-of-type {
  background-color: #f44336;
  color: white;
}

.modal-content button:last-of-type {
  background-color: #4caf50;
  color: white;
}

.modal-content.dark-mode button {
  background-color: #555;
}

.modal-content.dark-mode button:first-of-type {
  background-color: #d32f2f;
}

.modal-content.dark-mode button:last-of-type {
  background-color: #388e3c;
}
