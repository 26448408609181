.dashboard-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.dashboard-title-text h1 {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--text-primary);
  margin-bottom: 0.5rem;
  letter-spacing: -0.025em;
}

.dashboard-title-text p {
  color: var(--text-secondary);
}

.dashboard-title-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.settings-link {
  margin-left: 10px;
  font-size: 24px;
  color: var(--text-primary);
  text-decoration: none;
  transition: color 0.3s ease;
}

.dark-mode .settings-link {
  color: var(--text-secondary);
}

.settings-link:hover {
  color: var(--primary-color);
}

.signout-button {
  background: var(--danger-gradient);
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.signout-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(239, 68, 68, 0.2);
}
