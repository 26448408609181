/* Extract header-controls and search/filter related styles from Dashboard.css */
/* Add these new styles for the header controls */
.header-controls {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex: 1;
  max-width: 100%;
  margin-right: 1rem;
}

/* Search bar styles */
.search-bar {
  position: relative;
  flex: 1;
  min-width: 100px;
  max-width: 300px; /* Reduced max-width */
  z-index: 1;
}

.search-bar input {
  width: 80%;
  height: 2.5rem; /* Match height with dropdown */
  padding: 0 1rem 0 2.5rem;
  border-radius: 0.75rem;
  border: 1px solid var(--border-color);
  background: transparent;
  color: var(--text-primary);
  font-size: 0.875rem;
  transition: all 0.2s ease;
}

.search-bar input:focus {
  outline: none;
  border-color: var(--accent-color);
  box-shadow: 0 0 0 3px rgba(99, 102, 241, 0.1);
}

.search-icon {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  color: var(--text-secondary);
  font-size: 0.875rem;
}

/* Category dropdown styles */
.category-dropdown {
  position: relative;
  z-index: 2;
  min-width: 140px; /* Set minimum width */
  max-width: 200px; /* Set maximum width */
  margin-left: 1rem; /* Added margin to separate from search bar */
}

.dropdown-trigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  padding: 0 1rem;
  height: 2.5rem; /* Match height with search bar */
  border-radius: 0.75rem;
  border: 1px solid var(--border-color);
  background: transparent;
  color: var(--text-primary);
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 100%;
}

.dropdown-menu {
  position: absolute;
  top: calc(100% + 0.5rem);
  left: 0;
  right: 0;
  background: var(--card-bg);
  border-radius: 0.75rem;
  border: 1px solid var(--border-color);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  animation: slideDown 0.2s ease;
  z-index: 2000; /* Ensure dropdown appears above other content */
}

/* Dark mode support for dropdown */
.dark-mode .dropdown-menu {
  background: rgba(30, 41, 59, 0.95);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border-color: var(--border-color);
}

.overdue-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--danger-color) !important;
}

.overdue-option.active {
  background: var(--danger-gradient) !important;
  color: white !important;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .header-controls {
    flex-direction: column;
    align-items: stretch;
    gap: 0.5rem;
  }

  .overdue-button {
    width: 100%;
    justify-content: center;
  }
}
/* Add styles for the separate overdue button */
.overdue-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0 1rem;
  height: 2.5rem;
  border-radius: 0.75rem;
  border: none;
  background: linear-gradient(135deg, #ef4444, #f87171);
  color: white;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.overdue-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(239, 68, 68, 0.2);
}

.overdue-button.active {
  box-shadow: 0 0 20px rgba(239, 68, 68, 0.3);
}

.overdue-count {
  background: rgba(255, 255, 255, 0.2);
  padding: 0.25rem 0.5rem;
  border-radius: 1rem;
  font-size: 0.875rem;
}
