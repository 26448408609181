.message-type-dropdown {
  position: relative;
  display: inline-block;
}

.type-select {
  appearance: none;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px 24px 4px 8px;
  font-size: var(--font-size-sm);
  font-weight: 500;
  letter-spacing: 0.025em;
  cursor: pointer;
  color: #666;
  transition:
    background-color 0.3s,
    color 0.3s,
    border-color 0.3s;
}

.type-select:hover {
  background-color: #e8e8e8;
}

/* Add dark mode styles */
.dark-mode .type-select {
  background-color: #333;
  border-color: #444;
  color: #e0e0e0;
}

.dark-mode .type-select:hover {
  background-color: #444;
}

.dropdown-icon {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #666;
  font-size: 0.7rem;
  transition: color 0.3s;
}

/* Add dark mode style for the dropdown icon */
.dark-mode .dropdown-icon {
  color: #e0e0e0;
}

.snooze-options {
  background: var(--card-bg);
  border-radius: 0.75rem;
  box-shadow: var(--card-shadow);
  border: 1px solid var(--border-color);
  padding: 0.5rem;
  z-index: 1000;
  width: 200px;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.snooze-options button {
  background: none;
  border: none;
  padding: 4px 8px;
  cursor: pointer;
  text-align: left;
  border-radius: 2px;
  color: #333; /* Add default color */
  transition:
    background-color 0.3s,
    color 0.3s; /* Add transition */
}

/* Update dark mode styles */
.dark-mode .snooze-options {
  background-color: #333;
  border-color: #444;
}

.dark-mode .snooze-options button {
  color: #e0e0e0; /* Make text white in dark mode */
}

.dark-mode .snooze-options button:hover {
  background-color: #444;
}

.message-card {
  background: var(--card-bg);
  border-radius: 0.75rem;
  padding: 1.25rem;
  box-shadow: var(--card-shadow);
  transition:
    all 0.3s ease,
    max-height 0.3s ease;
  border: 1px solid var(--border-color);
  position: relative;
  overflow: hidden;
  margin-bottom: 0.5rem;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  display: flex;
  flex-direction: column;
}

.message-card:hover {
  transform: translateY(-2px);
  box-shadow: var(--card-hover-shadow);
}

.message-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: var(--primary-color);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.message-card:hover::before {
  opacity: 0; /* Remove the top border effect on hover */
}

.message-header {
  margin-bottom: 1.25rem;
}

.message-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.left-controls {
  display: flex;
  align-items: center;
  gap: 0.375rem;
}

.right-controls {
  display: flex;
  align-items: center;
  gap: 0.375rem;
}

.message-title-container {
  display: flex;
  align-items: center;
  flex: 1;
  margin: 0 1rem;
  min-width: 0;
}

.message-title {
  position: relative;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  width: 100%;
}

.message-title[data-title]:hover::after {
  content: attr(data-title);
  position: fixed;
  left: 40px;
  top: 50px;
  transform: translate(-10%, -10%);
  background: rgba(0, 0, 0, 0.9);
  color: white;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 0.875rem;
  white-space: normal;
  max-width: 300px;
  z-index: 1000;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  pointer-events: none; /* Prevents tooltip from interfering with hover */
}

/* Dark mode styles for the tooltip */
.dark-mode .message-title[data-title]:hover::after {
  background: rgba(255, 255, 255, 0.9);
  color: black;
  box-shadow: 0 2px 8px rgba(255, 255, 255, 0.2);
}

.message-title svg {
  width: 1.25rem;
  height: 1.25rem;
  flex-shrink: 0;
}

.message-actions {
  display: flex;
  gap: 0.375rem;
  align-items: center;
}

.icon-button {
  padding: 0.375rem;
  border-radius: 0.375rem;
  color: var(--text-secondary);
  transition: all 0.2s ease;
  background: var(--tag-bg);
  border: none;
  cursor: pointer;
}

.icon-button:hover {
  color: var(--accent-color);
  transform: translateY(-1px);
  background: linear-gradient(to right, var(--tag-bg), rgba(139, 92, 246, 0.1));
}

.icon-button svg {
  width: 1rem;
  height: 1rem;
}

.message-content {
  color: var(--text-secondary);
  line-height: 1.6;
  margin-bottom: 0.875rem;
  font-size: var(--font-size-base);
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.message-preview-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 0.75rem;
  margin-bottom: 0.875rem;
}

.message-url {
  background: var(--tag-bg);
  padding: 0.625rem;
  border-radius: 0.75rem;
  margin-bottom: 0.875rem;
  display: flex;
  align-items: center;
  gap: 0.375rem;
}

.message-link {
  color: var(--primary-color);
  text-decoration: none;
  font-size: var(--font-size-sm);
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.375rem;
  margin-bottom: 0.875rem;
}

.tag {
  background: var(--tag-bg);
  color: var(--tag-color);
  padding: 0.375rem 0.75rem;
  border-radius: 2rem;
  font-size: var(--font-size-xs);
  font-weight: 500;
  letter-spacing: 0.025em;
  transition: all 0.2s ease;
  cursor: pointer;
  border: 1px solid transparent;
}

.tag:hover {
  background: var(--accent-gradient);
  color: white;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(139, 92, 246, 0.2);
}

.message-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  padding-top: 0.875rem;
  border-top: 1px solid var(--border-color);
  color: var(--text-tertiary);
  font-size: var(--font-size-xs);
  font-weight: 500;
  gap: 0.75rem;
}

/* Status Indicators */
.message-card.overdue::before {
  background: #ef4444;
  opacity: 1;
}

.message-card.overdue {
  position: relative;
  border: none;
}

.message-card.overdue::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0.75rem;
  background: rgba(239, 68, 68, 0.1);
  z-index: -1;
  animation: glowPulse 2s infinite;
}

@keyframes glowPulse {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.3;
  }
}

.reminder-indicator,
.snoozed-indicator {
  font-size: 1rem;
  color: var(--text-secondary);
  display: flex;
  align-items: center;
}

.reminder-indicator,
.snoozed-indicator,
.overdue-indicator {
  padding: 0;
  background: none;
  border-radius: 0;
}

/* Type Dropdown */
.type-select {
  background: var(--accent-gradient);
  color: white;
  border: none;
  padding: 0.5rem 2rem 0.5rem 1rem;
  border-radius: 0.75rem;
  cursor: pointer;
  appearance: none;
  font-size: var(--font-size-sm);
  font-weight: 500;
  letter-spacing: 0.025em;
}

.type-select option {
  background: var(--card-bg);
  color: var(--text-primary);
}

.dropdown-icon {
  right: 0.75rem;
  color: var(--text-secondary);
}

/* Snooze Options */
.snooze-options {
  background: var(--card-bg);
  border-radius: 0.75rem;
  box-shadow: var(--card-shadow);
  border: 1px solid var(--border-color);
  padding: 0.5rem;
  z-index: 100;
  gap: 0.25rem;
}

.snooze-options button {
  width: 100%;
  padding: 0.5rem 0.75rem;
  text-align: left;
  border-radius: 0.5rem;
  color: var(--text-primary);
  transition: all 0.2s ease;
}

.snooze-options button:hover {
  background: var(--tag-bg);
}

/* Custom Date Picker */
.custom-date-picker {
  background: var(--card-bg);
  border-radius: 0.75rem;
  box-shadow: var(--card-shadow);
  border: 1px solid var(--border-color);
  padding: 0.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.custom-date-picker input {
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid var(--border-color);
  background: var(--tag-bg);
  color: var(--text-primary);
}

.custom-date-actions {
  display: flex;
  gap: 0.375rem;
}

.custom-date-actions button {
  flex: 1;
  padding: 0.625rem;
  border-radius: 0.5rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.custom-date-actions button:first-child {
  background: var(--primary-color);
  color: white;
}

.custom-date-actions button:last-child {
  background: var(--tag-bg);
  color: var(--text-primary);
}

/* Status Indicators */
.icon-button.complete {
  background: var(--success-gradient);
  color: white;
}

.icon-button.inactive {
  background: var(--tag-bg);
  color: var(--text-tertiary);
}

.icon-button.delete {
  background: var(--danger-gradient);
  color: white;
}

/* Collapsible Card */
.message-card {
  /* ... existing styles ... */
  transition:
    all 0.3s ease,
    max-height 0.3s ease;
}

.message-card.collapsed {
  max-height: 80px;
  overflow: hidden;
}

.message-card.collapsed .message-content,
.message-card.collapsed .message-preview-image,
.message-card.collapsed .message-url,
.message-card.collapsed .tags,
.message-card.collapsed .message-footer {
  display: none;
}

.collapse-toggle {
  position: static;
  background: var(--tag-bg);
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  color: var(--text-secondary);
  margin-left: auto;
}

.collapse-toggle:hover {
  background: var(--accent-gradient);
  color: white;
  transform: translateY(-1px);
}

.collapse-toggle svg {
  transition: transform 0.3s ease;
}

.message-card.collapsed .collapse-toggle svg {
  transform: rotate(180deg);
}

/* Improve header layout with collapse button */
.message-header {
  padding-right: 0;
}

/* Add hover effect for interactive elements */
.message-card .interactive-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--accent-gradient);
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  border-radius: 0.75rem;
}

.message-card:hover .interactive-overlay {
  opacity: 0.02;
}

.snooze-container {
  position: relative;
  display: inline-block;
}

.snooze-container .icon-button {
  position: relative;
  z-index: 1;
}

.link-button {
  background-color: purple; /* Set the background color to purple */
  color: white; /* Set the text color to white for contrast */
  border: none; /* Remove default border */
  border-radius: 4px; /* Optional: add some border radius */
  padding: 6px; /* Add some padding for better appearance */
  cursor: pointer; /* Change cursor to pointer on hover */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.link-button:hover {
  background-color: darkviolet; /* Darker shade on hover */
}
