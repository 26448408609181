/* Extract control-buttons related styles from Dashboard.css */
/* Create a new container for the right-side buttons */
.control-buttons {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding-left: 1rem;
  position: relative;
}

.refresh-button {
  background: var(--success-gradient);
  color: white;
  border: none;
  height: 2.5rem;
  border-radius: 0.75rem;
  border: none;
  padding: 0 1rem;
  align-items: center;
}

.refresh-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(16, 185, 129, 0.2);
}

.theme-toggle {
  background: transparent;
  border: 1px solid var(--border-color);
  color: var(--text-primary);
}
