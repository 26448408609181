.phone-setup-container {
  max-width: 400px;
  margin: 40px auto;
  padding: 20px;
  text-align: center;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.phone-setup-container h1 {
  margin-bottom: 20px;
  color: #333;
}

.phone-setup-container p {
  margin-bottom: 20px;
  color: #666;
}

.phone-setup-container form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.phone-setup-container input {
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.phone-setup-container button {
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.phone-setup-container button:hover {
  background-color: #0056b3;
}

.phone-setup-container .error {
  color: #dc3545;
  margin-top: 10px;
}

.phone-setup-container .terms {
  font-size: 14px;
  color: #666;
  margin-top: 20px;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 4px;
}

/* Dark mode support */
body.dark-mode .phone-setup-container {
  background: #2d2d2d;
}

body.dark-mode .phone-setup-container h1 {
  color: #fff;
}

body.dark-mode .phone-setup-container p {
  color: #ccc;
}

body.dark-mode .phone-setup-container input {
  background-color: #3d3d3d;
  border-color: #4d4d4d;
  color: #fff;
}

body.dark-mode .phone-setup-container .terms {
  background-color: #3d3d3d;
  color: #ccc;
}

.phone-setup-container select {
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
  background-color: white;
}

body.dark-mode .phone-setup-container select {
  background-color: #3d3d3d;
  border-color: #4d4d4d;
  color: #fff;
}
