.snooze-options {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: var(--card-bg);
  border: 1px solid var(--border-color);
  border-radius: 0.75rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  box-shadow: var(--card-shadow);
  z-index: 100;
}

.snooze-options button {
  width: 100%;
  padding: 0.5rem 0.75rem;
  text-align: left;
  border-radius: 0.5rem;
  color: var(--text-primary);
  transition: all 0.2s ease;
  border: none;
  background: none;
  cursor: pointer;
}

.snooze-options button:hover {
  background: var(--tag-bg);
}

.custom-date-picker {
  padding: 0.5rem;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.custom-date-picker input {
  width: 92%;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid var(--border-color);
  background: var(--tag-bg);
  color: var(--text-primary);
  margin-bottom: 0;
}

.custom-date-actions {
  display: flex;
  gap: 0.375rem;
}

.custom-date-actions button {
  flex: 1;
  text-align: center;
}

.custom-date-actions button:first-child {
  background: var(--primary-color);
  color: white;
}

.snooze-options .clear-reminder {
  color: #dc3545;
}

.snooze-options .clear-reminder:hover {
  background-color: #ffebee;
}
