.message-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.message-modal-content {
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 2rem;
  border-radius: 1rem;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  font-family: var(--font-primary);
}

.message-modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--text-color);
  padding: 0.5rem;
  line-height: 1;
  z-index: 1;
}

.message-card-full {
  width: 100%;
}

.message-preview-image-full {
  max-width: 100%;
  max-height: 400px;
  height: auto;
  margin: 1rem 0;
  object-fit: contain;
  display: block;
}

.message-content-full {
  white-space: pre-wrap;
  margin: 1rem 0;
  line-height: 1.5;
  font-size: var(--font-size-base);
  color: var(--text-primary);
}

.additional-notes {
  margin: 1rem 0;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.message-modal-content::-webkit-scrollbar {
  width: 8px;
}

.message-modal-content::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.message-modal-content::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.message-modal-content::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.3);
}

.message-edit-form {
  padding: 1rem;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: 0.5rem;
  background-color: var(--background-color);
  color: var(--text-color);
  transition: border-color 0.3s ease;
  font-family: var(--font-primary);
  font-size: var(--font-size-base);
  line-height: 1.5;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
}

.form-group textarea {
  min-height: 100px;
  resize: vertical;
}

.modal-actions {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.save-button,
.cancel-button,
.edit-button {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
  font-size: var(--font-size-sm);
  letter-spacing: 0.025em;
}

.save-button {
  background-color: var(--primary-color);
  color: white;
}

.cancel-button {
  background-color: var(--border-color);
  color: var(--text-color);
}

.edit-button {
  position: absolute;
  top: 1rem;
  right: 3.5rem;
  background-color: var(--primary-color);
  color: white;
}

.message-type-badge {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  background-color: var(--primary-color);
  color: white;
  border-radius: 4px;
  margin: 0.5rem 0;
  font-size: var(--font-size-xs);
  font-weight: 500;
  letter-spacing: 0.025em;
  text-transform: uppercase;
}

.message-url {
  display: block;
  margin-top: 1rem;
  color: var(--primary-color);
  word-break: break-all;
}

.save-button:hover,
.edit-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(59, 130, 246, 0.2);
}

.status-indicator {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.375rem 0.75rem;
  border-radius: 1rem;
  font-size: var(--font-size-sm);
  font-weight: 500;
  margin-bottom: 1rem;
}

.status-indicator.done {
  background: var(--success-gradient);
  color: white;
}

.status-indicator.overdue {
  background: var(--danger-gradient);
  color: white;
}

.status-indicator.pending {
  background: var(--accent-gradient);
  color: white;
}

.status-indicator svg {
  font-size: 1rem;
}
