.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  gap: 1rem;
}

.pagination-button {
  padding: 0.5rem 1rem;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: var(--card-bg);
  color: var(--text-primary);
  cursor: pointer;
  transition: all 0.2s ease;
}

.pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination-button:not(:disabled):hover {
  background-color: var(--tag-bg);
  transform: translateY(-1px);
}

.pagination-info {
  font-size: 0.9rem;
  color: var(--text-secondary);
}

.dark-mode .pagination-button {
  border-color: var(--border-color);
  background-color: var(--card-bg);
}

.dark-mode .pagination-button:not(:disabled):hover {
  background-color: var(--tag-bg);
}
