/* Import Inter font */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

/* Light mode variables */
:root {
  --bg-gradient: linear-gradient(135deg, #f5f7fa 0%, #e4e9f2 100%);
  --card-bg: #ffffff;
  --card-shadow: 0 8px 24px rgba(149, 157, 165, 0.1);
  --card-hover-shadow: 0 12px 28px rgba(149, 157, 165, 0.2);
  --primary-color: #3b82f6;
  --secondary-color: #6366f1;
  --accent-color: #8b5cf6;
  --accent-gradient: linear-gradient(135deg, #6366f1 0%, #8b5cf6 100%);
  --danger-color: #ef4444;
  --danger-gradient: linear-gradient(135deg, #ef4444 0%, #f87171 100%);
  --success-color: #10b981;
  --success-gradient: linear-gradient(135deg, #10b981 0%, #34d399 100%);
  --text-primary: #1a1a1a;
  --text-secondary: #4a5568;
  --text-tertiary: #718096;
  --text-inverse: #ffffff;
  --border-color: #e5e7eb;
  --tag-bg: #f3f4f6;
  --tag-color: #374151;
  --button-hover: #2563eb;
  --font-primary: "Inter", -apple-system, BlinkMacSystemFont, sans-serif;
  --font-size-xs: 0.75rem;
  --font-size-sm: 0.875rem;
  --font-size-base: 1rem;
  --font-size-lg: 1.125rem;
  --font-size-xl: 1.25rem;
  --font-size-2xl: 1.5rem;
  --font-size-3xl: 1.875rem;
}

/* Dark mode variables */
.dark-mode {
  --bg-gradient: linear-gradient(135deg, #1e1b4b 0%, #312e81 100%);
  --card-bg: rgba(30, 41, 59, 0.8);
  --card-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  --card-hover-shadow: 0 12px 28px rgba(0, 0, 0, 0.3);
  --primary-color: #60a5fa;
  --secondary-color: #818cf8;
  --accent-color: #a78bfa;
  --accent-gradient: linear-gradient(135deg, #818cf8 0%, #a78bfa 100%);
  --danger-color: #ef4444;
  --danger-gradient: linear-gradient(135deg, #ef4444 0%, #f87171 100%);
  --success-color: #10b981;
  --success-gradient: linear-gradient(135deg, #10b981 0%, #34d399 100%);
  --text-primary: #f7fafc;
  --text-secondary: #e2e8f0;
  --text-tertiary: #a0aec0;
  --text-inverse: #1a1a1a;
  --border-color: #374151;
  --tag-bg: #374151;
  --tag-color: #e5e7eb;
  --button-hover: #3b82f6;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--font-size-base);
  line-height: 1.5;
  color: var(--text-primary);
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  line-height: 1.25;
  margin: 0 0 1rem;
}

h1 {
  font-size: var(--font-size-3xl);
  font-weight: 700;
  letter-spacing: -0.025em;
}

h2 {
  font-size: var(--font-size-2xl);
  font-weight: 600;
}

h3 {
  font-size: var(--font-size-xl);
  font-weight: 600;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.message-list {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.message-item {
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;
  list-style-type: none;
}

.message-item h3 {
  margin-top: 0;
  color: #333;
}

.tags {
  margin-top: 10px;
}

.tag {
  background-color: #007bff;
  color: white;
  padding: 3px 8px;
  border-radius: 3px;
  margin-right: 5px;
  font-size: 0.8em;
}

.dashboard {
  padding: 1.5rem;
  max-width: 1400px;
  margin: 0 auto;
  min-height: 100vh;
  background: var(--bg-gradient);
}
