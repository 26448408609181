.signin-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  padding: 20px;
}

.signin-content {
  background: white;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
  text-align: center;
}

.app-logo {
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
}

.signin-container h1 {
  color: #2c3e50;
  margin-bottom: 15px;
  font-size: 2em;
  font-weight: normal;
}

.app-description {
  color: #666;
  margin-bottom: 30px;
  line-height: 1.6;
  font-size: 1.1em;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.signin-prompt {
  color: #2c3e50;
  margin-bottom: 15px;
  font-size: 1.1em;
}

.google-signin {
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.features {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 40px;
  padding-top: 30px;
  border-top: 1px solid #eee;
}

.feature-item {
  text-align: center;
}

.feature-icon {
  font-size: 2em;
  margin-bottom: 10px;
  display: block;
}

.feature-item p {
  color: #2c3e50;
  font-size: 0.9em;
  margin: 0;
}

.how-it-works {
  margin-top: 40px;
  padding-top: 30px;
  border-top: 1px solid #eee;
  text-align: left;
}

.how-it-works h2 {
  color: #2c3e50;
  margin-bottom: 30px;
  font-size: 1.5em;
  font-weight: normal;
}

.steps {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.step {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  text-align: left;
}

.step-number {
  background: #007bff;
  color: white;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  flex-shrink: 0;
}

.step-content {
  flex: 1;
}

.step-content h3 {
  color: #2c3e50;
  margin: 0 0 8px 0;
  font-size: 1.1em;
  font-weight: normal;
}

.step-content p {
  color: #666;
  margin: 0;
  line-height: 1.4;
  font-size: 0.95em;
}

@media (max-width: 600px) {
  .signin-content {
    padding: 30px 20px;
  }

  .features {
    grid-template-columns: 1fr;
    gap: 15px;
  }
}

.google-signin button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
}

.google-signin button:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.tips {
  margin-top: 40px;
  padding-top: 30px;
  border-top: 1px solid #eee;
  text-align: left;
}

.tips h2 {
  color: #2c3e50;
  margin-bottom: 20px;
  font-size: 1.5em;
  font-weight: normal;
}

.tips ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.tips li {
  margin-bottom: 12px;
  color: #666;
  font-size: 1em;
  line-height: 1.4;
}
